<template>
    <div style="background-color: #ffffff;">

        <div class="pageCenter text-left m-b-20 p-10">
            <div class="f24 bold text-center">打印清单</div>
            <div class="df-jc-s-b">
                <lineTitle text="清单"></lineTitle>
                <el-button type="">修改清单备注</el-button>
            </div>
            <div>
                <el-table :data="[0]" style="width: 100%">
                    <el-table-column align="center" prop="date" label="订单编号" width="180">
                        <div>{{orderId}}</div>
                    </el-table-column>
                    <el-table-column align="center" prop="name" label="下单账户" width="180">
                        <div>{{detail.orderUserauthNickname}}</div>
                        <div class="c-2">ID:{{detail.orderPayUserid}}</div>
                    </el-table-column>
                    <el-table-column align="center" prop="address" label="下单时间">
                        <div>{{detail.orderCreatetime}}</div>
                    </el-table-column>
                    <el-table-column align="center" prop="address" label="发货时间"></el-table-column>
                    <el-table-column align="center" prop="address" label="收货人">
                        <div>{{detail.orderPostAddressUsername}}</div>
                    </el-table-column>
                </el-table>
                <el-table :data="detail.orderSchemeSpuDtos" style="width: 100%">
                    <el-table-column align="center" prop="date" label="序号" width="50" type="index"></el-table-column>
                    <el-table-column align="center" prop="name" label="商品图片" width="180">
                        <template  slot-scope="scope">
                            <img :src="$oucy.ossUrl + scope.row.productAvata" class="productAvata">
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="spuName" label="商品名称"></el-table-column>
                    <el-table-column align="center" prop="skuName" label="商品规格"></el-table-column>
                    <el-table-column align="center" prop="" label="商家名称">{{
                        detail.enterpriseName
                    }}</el-table-column>
                    <el-table-column align="center" prop="pruductCount" label="商品数量"></el-table-column>
                    <el-table-column align="center" prop="productPrise" label="商品单价"></el-table-column>
                </el-table>
                <el-table :data="[0]" style="width: 100%">
                    <el-table-column align="center" prop="name" label="商品总价" width="180">￥{{detail.orderAmount}}</el-table-column>
                    <el-table-column align="center" prop="address" label="运费">￥{{detail.orderPostAmount||0}}</el-table-column>
                    <el-table-column align="center" prop="address" label="付款方式">
                        <div class="f12 df-ai-c df-jc-c text-center" v-if="detail.orderPayChannel===0"><img src="@/assets/images/stockCar/WeChat.png" alt="">微信支付</div>
                        <div class="f12 df-ai-c df-jc-c text-center" v-if="detail.orderPayChannel===1"><img src="@/assets/images/stockCar/Alipay.png" alt="">支付宝</div>
                    </el-table-column>
                    <el-table-column align="center" prop="address" label="实付款">
                        ￥{{detail.orderPayfee}}
                    </el-table-column>
                    <el-table-column align="center" prop="address" label="付款时间">{{detail.orderPaytime}}</el-table-column>
                    <el-table-column align="center" prop="address" label="付款方">{{detail.orderUserauthNickname}}</el-table-column>
                    <el-table-column align="center" prop="address" label="清单备注"></el-table-column>
                </el-table>
                
            </div>
            <div class="text-center m-t-20 p-b-50 no-print">
                <el-button type="primary">打印</el-button>
                <el-button type="">返回</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import { schemeOrder } from '@/service/index.js'
import { localSet, localGet, localDel } from "@/store/store"

export default {
    name: "refund",
    data() {
        return {
            pageWidth: oucy.pageWidth,
            form: {},
            statu: 0,
            input3: null,
            select: null,
            queryFurnitureLibraryObj: {},
            input: null,
            orderId: null,
            splitOrder: null,
            orderData: {},
            detail:{},
            orderRecycle: []
        }
    },
    computed: {

    },
    mounted() {
 this.orderId = this.$route.query.orderId
 this.splitOrder = this.$route.query.splitOrder=='true'?true:false
 this.queryOrderDetail()
    },
    methods: {
        // 切换页数
        sizeChange: function(pageSize) {
            const me = this;
            me.QueryFurnitureLibrary.limit = pageSize;
            // me.queryFurnitureLibrary();
        },
        // 切换页码
        currentChange: function(current) {
            const me = this;
            me.QueryFurnitureLibrary.start = current - 1;
            // me.queryFurnitureLibrary();
        },
        onSubmit() {},
        queryOrderDetail() {
            schemeOrder.queryOrderDetail({ orderId: this.orderId, splitOrder: this.splitOrder }).then(res => {
                this.orderData = res
                this.detail=res&&res.orderSchemes&&res.orderSchemes[0]
                if(this.detail && this.detail.orderLifeCycle && this.detail.orderLifeCycle.length > 0) {
                  this.orderRecycle = JSON.parse(this.detail.orderLifeCycle);
                }
            })
        },
    }
}
</script>
<style scoped lang="less">
.productAvata{
    width: 100px;
    height: 100px;
}
</style>
